import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";
const axios = createHttp(appConfig.base2GUrl);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
// 企业管理分页
export function getEnterpriseList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insInsured/enterpriseManagementPage",
    method: "POST",
    loading: true,
    data,
  });
}

//保单管理分页
export function getPolicyList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/pageLiabilityInsurancePolicy",
    method: "POST",
    loading: true,
    data,
  });
}

//根据保司名获取保司信息
export function getInsuranceCompanyInfos(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insCompany/getCoverageByCompanyName",
    method: "GET",
    loading: true,
    params,
  });
}

//从业人员名单导入
export function importProfessional(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/importSubject",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    loading: true,
    data,
  });
}

//安全责任险保单提交
export function submitLiabilityInsurancePolicy(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/addLiabilityInsurancePolicy",
    method: "POST",
    loading: true,
    data,
  });
}
//安全责任险保单详情
export function getLiabilityInsurancePolicy(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/detailsLiabilityInsurancePolicy",
    method: "POST",
    loading: true,
    data,
  });
}
//安全责任险保单删除
export function deleteLiabilityInsurancePolicy(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/deleteLiabilityInsurancePolicy",
    method: "GET",
    loading: true,
    params,
  });
}

//理赔列表获取
export function getClaimList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/azxClaims/page",
    method: "POST",
    loading: true,
    data,
  });
}

//数据看板主页数据
export function getDataBoard() {
  return SystemAxios.axios({
    url: "/ins-policy/dataBoard/dataKanbanMap",
    method: "GET",
    loading: true,
  });
}

//保险公司列表
export function getInsuranceCompanyList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insCompany/pageInsCompany",
    method: "POST",
    loading: true,
    data,
  });
}

//删除保险公司
export function delInsuranceCompany(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insCompany/deleteInsCompany",
    method: "GET",
    loading: true,
    params,
  });
}

//企业详情
export function getEnterpriseDetail(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insInsured/enterpriseManagementDetails",
    method: "POST",
    loading: true,
    data,
  });
}

//保险公司数据看板
export function getInsuranceCompanyDataBoard(data) {
  return SystemAxios.axios({
    url: "/ins-policy/dataBoard/dataKanbanCompany",
    method: "POST",
    loading: true,
    data,
  });
}
//保险公司数据看板列表-保单列表
export function getInsuranceCompanyDataBoardPolicyList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/dataBoard/dataKanbanCompanyPolicy",
    method: "POST",
    loading: true,
    data,
  });
}

//保险公司数据看板列表-案件列表
export function getInsuranceCompanyDataBoardClaimList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/dataBoard/dataKanbanCompanyClaims",
    method: "POST",
    loading: true,
    data,
  });
}
//企业数据看板
export function getEnterpriseDataBoard(data) {
  return SystemAxios.axios({
    url: "/ins-policy/dataBoard/dataKanbanInsured",
    method: "POST",
    loading: true,
    data,
  });
}

//服务数据看板
export function getServiceDataBoard(data) {
  return SystemAxios.axios({
    url: "/ins-policy/dataBoard/serviceData",
    method: "POST",
    loading: true,
    data,
  });
}

//服务列表
export function getServiceList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/serviceManagement/page",
    method: "POST",
    loading: true,
    data,
  });
}

//服务详情
export function getServiceDetail(params) {
  return SystemAxios.axios({
    url: "/ins-policy/serviceManagement/detail",
    method: "GET",
    loading: true,
    params,
  });
}
//服务添加编辑
export function addEditService(data) {
  return SystemAxios.axios({
    url: "/ins-policy/serviceManagement/addOrUpdate",
    method: "POST",
    loading: true,
    data,
  });
}

//服务删除
export function deleteService(params) {
  return SystemAxios.axios({
    url: "/ins-policy/serviceManagement/delete",
    method: "GET",
    loading: true,
    params,
  });
}
//服务导出
export function exportService(data) {
  return SystemAxios.axios({
    url: "/ins-policy/serviceManagement/pageExport",
    method: "POST",
    loading: true,
    responseType: "blob",
    data,
  });
}

//保单勾选导出
export function exportPolicyByIds(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/deriveLiabilityInsurancePolicy",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
}

//根据被保险人模糊查保单
export function getPolicyListByBlurMan(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/getPolicyByInsuredName",
    method: "GET",
    params,
  });
}

//服务记录分页
export function getServiceRecordPage(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/servicePage",
    method: "GET",
    loading: true,
    params,
  });
}

//理赔记录分页
export function getClaimRecordPage(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/claimsPage",
    method: "GET",
    loading: true,
    params,
  });
}

//保司详情
export function getInsuranceCompanyDetail(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insCompany/detailsInsCompany",
    method: "GET",
    loading: true,
    params,
  });
}

//保司数据添加或编辑
export function addOrUpdateInsuranceCompany(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insCompany/addInsCompany",
    method: "POST",
    loading: true,
    data,
  });
}

//根据保单号模糊查询数据
export function getInsuranceCompanyByPolicyNo(params) {
  //{policyName:''}
  return SystemAxios.axios({
    url: "/ins-policy/insLiabilityInsurancePolicy/getPolicyByNum",
    method: "GET",
    loading: true,
    params,
  });
}

//理赔添加或编辑
export function addOrEditInsuranceClaim(data) {
  return SystemAxios.axios({
    url: "/ins-policy/azxClaims/addOrUpdate",
    method: "POST",
    loading: true,
    data,
  });
}

//理赔详情
export function getInsuranceClaimDetail(params) {
  //id
  return SystemAxios.axios({
    url: "/ins-policy/azxClaims/detail",
    method: "GET",
    loading: true,
    params,
  });
}

//导出理赔数据
export function exportInsuranceClaim(data) {
  return SystemAxios.axios({
    url: "/ins-policy/azxClaims/pageExport",
    method: "POST",
    loading: true,
    data,
    responseType: "blob",
  });
}

//删除理赔数据
export function deleteInsuranceClaim(params) {
  return SystemAxios.axios({
    url: "/ins-policy/azxClaims/delete",
    method: "GET",
    loading: true,
    params,
  });
}

//专家添加或编辑
export function addOrEditExpert(data) {
  return SystemAxios.axios({
    url: "/ins-policy/azxExpert/saveOrEditExpert",
    method: "POST",
    loading: true,
    data,
  });
}

// 专家列表
export function getExpertList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/azxExpert/pageList",
    method: "POST",
    loading: true,
    data,
  });
}

//获取专家详情
export function getExpertDetail(params) {
  // expertId
  return SystemAxios.axios({
    url: "/ins-policy/azxExpert/getDetail",
    method: "GET",
    loading: true,
    params,
  });
}

//删除专家
export function deleteExpert(params) {
  return SystemAxios.axios({
    url: "/ins-policy/azxExpert/removeDetail",
    method: "GET",
    loading: true,
    params,
  });
}

// 专家账号密码重置
export function expertPwdReset(params) {
  return SystemAxios.axios({
    url: "/ins-policy/azxExpert/resetPassword",
    method: "GET",
    loading: true,
    params,
  });
}

//导出专家数据
export function exportDataExpert(data) {
  return SystemAxios.axios({
    url: "/ins-policy/azxExpert/exportExpert",
    method: "POST",
    loading: true,
    data,
    responseType: "blob",
  });
}

//服务字典列表
export function getServiceDictList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insServiceDict/paging",
    method: "POST",
    loading: true,
    data,
  });
}
//服务项目下拉
export function getServiceProjectOptions(params) {
  return SystemAxios.axios({
    url: "/ins-policy/insServiceDict/pullDown",
    method: "GET",
    loading: true,
    params,
  });
}

//添加服务字典
export function addServiceProject(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insServiceDict/addOrUpdate",
    method: "POST",
    loading: true,
    data,
  });
}
//删除服务字典
export function deletServiceProject(data) {
  return SystemAxios.axios({
    url: "/ins-policy/insServiceDict/delete",
    method: "POST",
    loading: true,
    data,
  });
}
//根据保单id获得服务列表
export function getServiceListByPolicyId(data, params) {
  return SystemAxios.axios({
    url: `/ins-policy/serviceManagement/selectByPolicyId?policyId=${params}`,
    method: "POST",
    loading: true,
    data,
  });
}
//根据保单id获得案件列表
export function getCaseListByPolicyId(data, params) {
  return SystemAxios.axios({
    url: `/ins-policy/azxClaims/selectByPolicyId?policyId=${params}`,
    method: "POST",
    loading: true,
    data,
  });
}

//编辑企业
export function editEnterprise(data) {
  return SystemAxios.axios({
    url: `/ins-policy/insInsured/edit`,
    method: "POST",
    loading: true,
    data,
  });
}
//根据专家名称模糊搜索
export function getExpertListBlurName(params) {
  return SystemAxios.axios({
    url: `/ins-policy/azxExpert/likeByName`,
    method: "GET",
    params,
    loading: true,
  });
}
//服务审核
export function serviceAudit(data) {
  return SystemAxios.axios({
    url: `/ins-policy/serviceManagement/examine`,
    method: "POST",
    loading: true,
    data,
  });
}
//交通安责险文章添加
export function addTrafficArticle(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaMessage/addOrUpdate`,
    method: "POST",
    loading: true,
    data,
  });
}

// 交通安责险文章列表
export function trafficArticleList(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaMessage/getPagePc`,
    method: "POST",
    loading: true,
    data,
  });
}
// 交通安责险文章删除
export function trafficArticleDelete(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaMessage/delete`,
    method: "POST",
    loading: true,
    data,
  });
}
// 交通安责险企业列表
export function trafficArticleEnterpriseList(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaCompany/getPage`,
    method: "POST",
    loading: true,
    data,
  });
}

// 交通安责险企业删除
export function trafficArticleEnterpriseDelete(params) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaCompany/delete`,
    method: "GET",
    loading: true,
    params,
  });
}
// 交通安责险企业详情
export function trafficArticleEnterpriseDetail(params) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaCompany/details`,
    method: "GET",
    loading: true,
    params,
  });
}
// 交通安责险企业编辑
export function trafficArticleEnterpriseEdit(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaCompany/edit`,
    method: "POST",
    loading: true,
    data,
  });
}

// 交通安责险企业导出
export function trafficArticleEnterpriseExport(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaCompany/derivePc`,
    method: "POST",
    loading: true,
    data,
    responseType: "blob",
  });
}

// 交通安责险预约列表
export function trafficArticleAppointmentList(data) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaServiceAppointment/getPagePc`,
    method: "POST",
    loading: true,
    data,
  });
}
// 交通安责险预约删除
export function trafficArticleAppointmentDelete(params) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaServiceAppointment/delete`,
    method: "GET",
    loading: true,
    params,
  });
}
// 交通安责险预约状态变更
export function trafficArticleAppointmentChange(params) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaServiceAppointment/handle`,
    method: "GET",
    loading: true,
    params,
  });
}
// 交通安责险预约详情
export function trafficArticleAppointmentDetail(params) {
  return SystemAxios.axios({
    url: `/ins-policy/jtaServiceAppointment/details`,
    method: "GET",
    loading: true,
    params,
  });
}
