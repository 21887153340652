<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <div slot="tabs" class="tabs">
        <div
          :class="['tabs-item', tab == ti.value ? 'tabs-item-active' : '']"
          v-for="(ti, index) in tabsConfig"
          :key="index"
          @click="tab = ti.value"
        >
          {{ ti.label }}
        </div>
      </div>
      <template slot="left-r" slot-scope="{ data }">
        <el-button
          class="dele-btn"
          icon="el-icon-circle-close"
          @click="batchDeletion(data.selectRows)"
          >删除</el-button
        >
        <el-button
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          @click="toDetail(true)"
          >添加</el-button
        >
      </template>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.selectRows)"
          >导出</el-button
        ></template
      >
      <div slot="action" slot-scope="{ data }">
        <el-button
          v-if="tab == 1"
          type="text"
          @click="toDetail(false, data.row)"
          >查看详情</el-button
        >
        <el-button type="text" @click="toDetail(true, data.row)"
          >编辑</el-button
        >
        <el-button @click="deletePolicy(data.row)" type="text">删除</el-button>
        <el-button v-if="tab == 1" type="text">理赔录入</el-button>
      </div>
      <template slot="policyNumber" slot-scope="{ data }">
        <el-button @click="toDetail(false, data.row)" type="text">{{
          data.row.policyNumber
        }}</el-button>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import {
  getPolicyList,
  deleteLiabilityInsurancePolicy,
  exportPolicyByIds,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable },
  data() {
    return {
      tab: "1",
      tabsConfig: [
        {
          label: "保单列表",
          value: "1",
        },
        {
          label: "草稿箱",
          value: "2",
        },
      ],
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        height: 500,
        searchConfigs: [
          {
            label: "保单状态",
            type: "select",
            modelKey: "policyStatusName",
            option: [
              { dictKey: "未起保", dictValue: "未起保" },
              { dictKey: "保障中", dictValue: "保障中" },
              { dictKey: "已过期", dictValue: "已过期" },
            ],
          },
          {
            label: "保单号",
            type: "input",
            modelKey: "policyNumber",
          },
          {
            label: "被保险人",
            type: "input",
            modelKey: "theInsured",
          },
          {
            label: "创建时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            modelKey: "createTime",
          },
          {
            label: "起保日期",
            type: "daterange",
            daterangeKeys: ["commencementDateStart", "commencementDateEnd"],
            daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "commencementDate",
          },
          {
            label: "保险到期",
            type: "daterange",
            daterangeKeys: [
              "expirationOfInsuranceStart",
              "expirationOfInsuranceEnd",
            ],
            daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "expirationOfInsurance",
          },
          {
            label: "保险公司",
            type: "input",
            modelKey: "insuranceCompany",
          },
          // {
          //   label: "录单员",
          //   type: "input",
          //   modelKey: "noteTaker",
          // },
          {
            label: "业务员",
            type: "input",
            modelKey: "salesman",
          },
          {
            label: "项目名称",
            type: "input",
            modelKey: "projectName",
          },
          // {
          //   label: "所属客户",
          //   type: "input",
          //   modelKey: "ownedCustomer",
          // },
          {
            label: "更新时间",
            type: "daterange",
            daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
            modelKey: "updateTime",
          },
        ],
        columnProps: [
          {
            prop: "1",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "policyNumber",
            label: "保单号",
            width: "",
            type: "",
            isShow: true,
            slot: true,
          },

          {
            prop: "insuredName",
            label: "被保险人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceCompanyName",
            label: "保险公司",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "planName",
            label: "方案名称",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "projectName",
            label: "项目名称",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "salesmanName",
            label: "业务员",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceStartDate",
            label: "起保日期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceEndDate",
            label: "保险到期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "totalPremium",
            label: "总保费（元）",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "policyStatusName",
            label: "保单状态",
            width: "",
            type: "",
            isShow: true,
            //slot: true,
          },
          {
            prop: "createTime",
            label: "创建时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "220px",
            type: "",
            isShow: true,
            slot: true,
          },
        ],
      },
    };
  },
  watch: {
    tab() {
      this.$refs.table.getData();
    },
  },
  methods: {
    async queryFun(queryParams) {
      let res = await getPolicyList({ ...queryParams, policyType: this.tab });
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    deletePolicy(item) {
      this.$confirm("是否删除", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteLiabilityInsurancePolicy({ policyIds: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    batchDeletion(arr = []) {
      this.$confirm("是否删除", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        arr.forEach((item) => {
          ids.push(item.id);
        });
        deleteLiabilityInsurancePolicy({ policyIds: ids.join(",") }).then(
          () => {
            this.$refs.table.getData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        );
      });
    },
    // 跳转详情或编辑
    toDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyManagementDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
    exportData(data = []) {
      if (!data.length) {
        this.$message({
          message: "请勾选要导出的数据",
          type: "warning",
        });
        return;
      }
      let ids = [];
      data.forEach((item) => {
        ids.push(item.id);
      });
      exportPolicyByIds({ ids: ids.join(",") });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
.tabs {
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  &-item {
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  &-item-active {
    font-weight: bold;
    color: #4278c9;
    position: relative;
  }
  &-item-active::after {
    content: "";
    width: 56px;
    height: 3px;
    background: #4278c9;
    border-radius: 2px;
    display: block;
    position: absolute;
    bottom: 0;
  }
}
</style>
